














































































import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';

import OperatorCreate from './components/OperatorCreate.vue';

import { operatorsGetters, operatorsActions } from './store/names';

export default Vue.extend({
    name: 'OperatorsPage',

    components: {
        OperatorCreate,
    },

    data() {
        return {
            loading: true,

            headers: [
                { text: 'Name', sortable: false, value: 'name' },
                { text: 'Email', sortable: false, value: 'email' },
                { text: 'Status', sortable: false, value: 'status' },
                { text: 'Superuser', sortable: false, value: 'isSuperuser' },
            ],

            limit: 5,
            page: 1,

            query: '',
        };
    },

    computed: {
        ...mapGetters({
            operators: operatorsGetters.operators,
            operatorsCount: operatorsGetters.operatorsCount,
        }),

        offset(): number {
            return this.operatorsCount > this.limit ? this.limit * (this.page - 1) : 0;
        },
    },

    watch: {
        async query() {
            await this.getOperators();
        },

        async limit() {
            await this.getOperators();
        },

        async page() {
            await this.getOperators();
        },
    },

    async mounted() {
        await this.getOperators();
    },

    methods: {
        ...mapActions({
            fetchOperators: operatorsActions.fetchOperators,
        }),

        async getOperators(): Promise<void> {
            this.loading = true;

            const payload = {
                limit: this.limit,
                offset: this.offset,
                query: this.query,
            };

            await this.fetchOperators({
                ...payload,
            }).then(() => {
                this.loading = false;
            });
        },
    },
});


















































































































import Vue from 'vue';

import { mapActions } from 'vuex';

import { required, minLength, email } from 'vuelidate/lib/validators';

import { operatorsActions } from '@/modules/operators/store/names';

export default Vue.extend({
    name: 'OperatorCreateForm',

    validations: {
        form: {
            name: { required },
            password: { required, minLength: minLength(8) },
            email: { required, email },
        },
    },

    data() {
        return {
            progress: false,

            form: {
                name: '',
                email: '',
                isActive: true,
                isSuperuser: false,
                password: '',
            },

            addOperatorModal: false,
            showPass: false,

            errors: [] as string[],
        };
    },

    computed: {
        nameErrors() {
            const errors: string[] = [];
            if (!this.$v.form.name?.$dirty) return errors;
            if (!this.$v.form.name.required) {
                errors.push('Name is required.');
            }
            return errors;
        },
        
        emailErrors() {
            const errors: string[] = [];

            if (!this.$v.form.email?.$dirty) return errors;
            
            if (!this.$v.form.email.email) {
                errors.push('Email must be valid.');
            }

            if (!this.$v.form.email.required) {
                errors.push('Email is required.');
            }

            return errors;
        },

        passwordErrors() {
            const errors: string[] = [];

            if (!this.$v.form.password?.$dirty) return errors;

            if (!this.$v.form.password.minLength) {
                errors.push('Min 8 characters.');
            }

            if (!this.$v.form.password.required) {
                errors.push('Password is required.');
            }
            
            return errors;
        },
    },

    methods: {
        ...mapActions({
            createOperator: operatorsActions.createOperator,
        }),

        async submitForm() {
            this.errors = [];
            this.$v.form.$touch();

            if (!this.$v.form.$invalid) {
                this.progress = true;

                await this.createOperator(this.form)
                    .then(() => {
                        this.progress = false;
                        this.addOperatorModal = false;

                        if (!this.addOperatorModal) {
                            this.resetForm();
                            this.$v.form.$reset();
                        }
                    })
                    .catch((e) => {
                        this.progress = false;

                        this.errors.push(e);
                    });
            }
        },

        dismissModal() {
            this.errors = [];
            this.addOperatorModal = false;
            this.resetForm();
            this.$v.$reset();
        },
        
        resetForm() {
            if (this.$refs.addNewOperatpor instanceof HTMLFormElement) {
                this.$refs.addNewOperatpor.reset();
            }
        },
    },
});
